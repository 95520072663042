import React from 'react';

const MarketingAgency = () => {
  return (
    <div className="p-8 bg-gradient-to-r from-blue-100 to-purple-100 min-h-screen">
      <div className="container mx-auto">
        <h1 className="text-5xl font-extrabold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-blue-500">
          In-Depth Marketing Research
        </h1>

        {/* Research Topic Section */}
        <section className="mb-16">
          <h2 className="text-4xl font-bold text-center mb-8 text-purple-700">
            Understanding Consumer Behavior in Digital Marketing
          </h2>
          <p className="text-center text-xl text-purple-800 mb-8">
            In the ever-evolving landscape of digital marketing, understanding consumer behavior is crucial for creating effective marketing strategies. Consumer behavior encompasses the study of individuals and groups in the purchasing process, including the decisions they make and the factors influencing these decisions.
          </p>

         
        </section>
      </div>
    </div>
  );
};

export default MarketingAgency;
